<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    :class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin: Breadcrumb -->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ truncate(title, 40) }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link
              :to="'/'"
              class="subheader-breadcrumbs-home"
              custom
            >
              <i class="flaticon2-shelter text-muted icon-1x"></i>
              <!--              <span class="svg-icon svg-icon-lg">-->
              <!--                &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
              <!--                <inline-svg :src="'/media/svg/icons/Navigation/Angle-right.svg'" />-->
              <!--                &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
              <!--              </span>-->
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <!-- eslint-disable-next-line vue/no-v-for-template-key-on-child -->
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
                custom
              >
                {{ truncate(breadcrumb.title, 30) }}
              </router-link>
              <span v-if="!breadcrumb.route" :key="i" class="text-muted">
                {{ truncate(breadcrumb.title, 30) }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <!-- end: Breadcrumb-->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
  import { mapGetters } from 'vuex'
  import KTTopbar from '@src/components/layout/header/Topbar.vue'
  import KTLayoutHeader from '@/assets/js/layout/base/header.js'
  // import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js'
  // import KTMenu from '@/view/layout/header/Menu.vue'
  import { truncate } from '@src/core/helpers/textUtils'

  export default {
    name: 'KTHeader',
    components: {
      KTTopbar
      // KTMenu
    },
    props: {
      breadcrumbs: {
        type: Array,
        default: () => []
      },
      title: {
        type: String,
        default: ''
      }
    },
    setup () {
      return {
        truncate
      }
    },
    computed: {
      ...mapGetters([
        'layoutConfig',
        'getClasses'
      ]),

      /**
       * Get extra classes for header based on the options
       * @returns {null|*}
       */
      headerClasses () {
        const classes = this.getClasses('header')
        if (typeof classes !== 'undefined') {
          return classes.join(' ')
        }
        return null
      }
    },
    mounted () {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init('kt_header', 'kt_header_mobile')

      // // Init Header Menu
      // KTLayoutHeaderMenu.init(
      //   this.$refs.kt_header_menu,
      //   this.$refs.kt_header_menu_wrapper
      // )
    }
  }
</script>
