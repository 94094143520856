<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
    >
      <div class="d-flex flex-column text-right pr-3">
        <span
          class="text-dark-50 font-weight-bold font-size-base d-none d-md-inline mr-3"
        >
          {{ username }}
        </span>
        <span class="text-primary font-weight-normal font-size-sm d-none d-md-inline mr-3">
          {{ email }}
        </span>
      </div>
      <span class="symbol symbol-35 symbol-light-success">
        <img alt="profile picture" :src="profilePicture" />
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ trans('users.userProfile') }}
        </h3>
        <a
          id="kt_quick_user_close"
          ref="kt_quick_user_close_btn"
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="profilePicture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ username }}
            </a>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-text text-muted text-hover-primary">
                    {{ email | truncate(25) }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ trans('users.logout') }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <div>
            <p style="opacity: .4;">Brukermenyer kommer her...</p>
          </div>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
  import objectPath from 'object-path' // Fixed includeInheritedProps
  const objectPathWIP = objectPath.create({ includeInheritedProps: true })

  import { GET_ALL, LOGOUT_FROM_FB } from '@src/store/auth'
  import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { truncate } from '@src/core/helpers/textUtils'
  import { computed, onMounted } from '@vue/composition-api'

  export default {
    name: 'KTQuickUser',
    setup (props, { root, refs }) {
      onMounted(() => {
        KTLayoutQuickUser.init(refs.kt_quick_user)
      })

      // !IMPORTANT: IF ANY VALUES FROM A COMPUTED PROPERY IS CHANGED, CLONE THE VALUE!
      const authUser = computed(() => root.$store.getters[GET_ALL])
      const profilePicture = computed(() => '/media/users/blank.png') // TODO: Dynamic profilePictures

      const username = computed(() => objectPathWIP.get(authUser, 'value.user.displayName', ''))
      const email = computed(() => objectPathWIP.get(authUser, 'value.user.email', ''))

      const onLogout = () => {
        root.$store.dispatch(LOGOUT_FROM_FB).then(() => root.$router.push({ name: 'login' }))
      }

      const hideMenu = () => refs.kt_quick_user_close_btn.click()

      return {
        authUser,
        profilePicture,
        username,
        email,
        trans,
        transChoice,
        truncate,
        onLogout,
        hideMenu
      }
    }
  }
</script>

<style lang="scss" scoped>
  #kt_quick_user {
    overflow: hidden;
  }
</style>
