<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="!requiredDataIsLoaded"></Loader>

    <!-- begin::Body -->
    <div v-else class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader
          :breadcrumbs="breadcrumbs"
          :title="pageTitle"
        ></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid mt-12">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <!--        <KTFooter></KTFooter>-->
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { GET_ALL, GET_AUTH_STATE } from '@src/store/auth' // Store

  // Custom modified theme files
  import KTSubheader from '@src/components/layout/subheader/Subheader.vue'
  import KTAside from '@src/components/layout/aside/Aside.vue'
  import KTHeader from '@src/components/layout/header/Header.vue'
  import CustomHtmlClass from '@src/core/services/customhtmlclass.service'
  import KTScrollTop from '@src/components/layout/extras/ScrollTop'

  // Metronic
  import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue'
  // import KTFooter from '@/view/layout/footer/Footer.vue'
  import HtmlClass from '@/core/services/htmlclass.service'
  import Loader from '@/view/content/Loader.vue'
  import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME
  } from '@/core/services/store/htmlclass.module.js'

  export default {
    name: 'Default',
    components: {
      KTAside,
      KTHeader,
      KTHeaderMobile,
      // KTFooter,
      KTSubheader,
      KTScrollTop,
      Loader
    },
    computed: {
      ...mapGetters([
        'layoutConfig',
        'breadcrumbs',
        'pageTitle'
      ]),
      /**
       * Check if all required data for the default layout has been loaded
       * Required:
       * - user (both from firebase and from database)
       */
      requiredDataIsLoaded () {
        if (
          this.$store.getters[GET_ALL] !== null &&
          this.$store.getters[GET_ALL].data !== null &&
          this.$store.getters[GET_ALL].user !== null &&
          this.$store.getters[GET_AUTH_STATE]
        ) { // auth.data is set in the store index.js and is required!
          console.log('Yep, everything is set!')
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
          return true
        }
        console.log('Nope, waiting...')
        return false
      },
      /**
       * Check if container width is fluid
       * @returns {boolean}
       */
      contentFluid () {
        return this.layoutConfig('content.width') === 'fluid'
      },

      /**
       * Check if the left aside menu is enabled
       * @returns {boolean}
       */
      asideEnabled () {
        return Boolean(this.layoutConfig('aside.self.display'))
      },

      /**
       * Set the subheader display
       * @returns {boolean}
       */
      subheaderDisplay () {
        return Boolean(this.layoutConfig('subheader.display'))
      }
    },
    beforeMount () {
      this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading')
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'full-layout')

      HtmlClass.init(this.layoutConfig())
      CustomHtmlClass.init(this.layoutConfig())
    }
  }
</script>
