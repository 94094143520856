var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":'/media/svg/icons/Layout/Layout-4-blocks.svg'}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.dashboard', 1))))])])])]}}])}),_c('router-link',{attrs:{"to":"/tasks","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":'/media/svg/icons/Communication/Clipboard-check.svg'}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.task', 2))))]),(_vm.countDone)?_c('span',{staticClass:"menu-label"},[_c('span',{staticClass:"label label-rounded",class:[_vm.pendingTasksCount > 0 ? 'label-primary' : 'label-success']},[_vm._v(" "+_vm._s(_vm.pendingTasksCount)+" ")])]):_vm._e()])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/templates') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":'/media/svg/icons/Design/Layers.svg'}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.template', 2))))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('i',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.template', 2))))])])]),_c('router-link',{attrs:{"to":"/templates","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.trans('nav.aside.templates.all')))])])])]}}])}),_c('router-link',{attrs:{"to":"/templates/add","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('b-link',{staticClass:"menu-link",on:{"click":function($event){return _vm.$bvModal.show('modal-templates-add')}}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.trans('nav.aside.templates.add')))])])],1)]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/products') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":'/media/svg/icons/Shopping/Box1.svg'}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.product', 2))))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('i',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.product', 2))))])])]),_c('router-link',{attrs:{"to":"/products","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.trans('nav.aside.products.all')))])])])]}}])}),_c('router-link',{attrs:{"to":"/products/sync","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.trans('nav.aside.products.sync')))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/sessions') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":'/media/svg/icons/General/User.svg'}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.session', 2))))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('i',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.session', 2))))])])]),_c('router-link',{attrs:{"to":"/sessions","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.trans('nav.aside.sessions.all', 2))))])])])]}}])})],1)])]),_c('router-link',{attrs:{"to":"/logs","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":'/media/svg/icons/Communication/Archive.svg'}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.transChoice('global.log', 0))))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }