<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img v-if="logo" alt="Logo" :src="logo" width="100" />
    <Logo v-else :width="160" text-color="#303440" dot-color="#1A73E8" />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
  import Logo from '@src/components/core/Logo'
  export default {
    name: "Loader",
    components: {
      Logo
    },
    props: {
      logo: String,
      spinnerClass: String
    }
  }
</script>
