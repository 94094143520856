<template>
  <div
    id="kt_subheader"
    class="subheader py-2 py-lg-4"
    :class="subheaderClasses"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <component :is="getActiveSubmenu" :subdata="getSubmenuData"></component>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { SETTINGS } from '@src/store/settings'

  import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js'

  export default {
    name: 'KTSubheader',
    components: {
      SubHeaderDashboard: () => import('@src/components/layout/subheader/pages/Dashboard'),
      SubHeaderTemplatesEdit: () => import('@src/components/layout/subheader/pages/TemplatesEdit')
    },
    computed: {
      ...mapGetters({
        layoutConfig: 'layoutConfig',
        getClasses: 'getClasses',
        getActiveSubmenu: SETTINGS.GETTERS.GET_ACTIVE_SUBMENU,
        getSubmenuData: SETTINGS.GETTERS.GET_SUBMENU_DATA
      }),

      /**
       * Check if subheader width is fluid
       */
      widthFluid () {
        return this.layoutConfig('subheader.width') === 'fluid'
      },

      subheaderClasses () {
        const classes = []
        const style = this.layoutConfig('subheader.style')
        if (style) {
          classes.push(style)

          if (style === 'solid') {
            classes.push('bg-white')
          }

          if (this.layoutConfig('subheader.fixed')) {
            classes.push('border-top')
          }
        }
        return classes.join(' ')
      }
    },
    mounted () {
      // Init Header Menu
      KTLayoutHeaderMenu.init(
        this.$refs.kt_header_menu,
        this.$refs.kt_header_menu_wrapper
      )
    }
  }
</script>
