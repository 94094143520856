<template>
  <div class="custom-logo" :style="style">
    <!--eslint-disable-->
    <img src="/img/sg_logo.jpg" :width="size" :height="size" />
  </div>
</template>

<script>
  export default {
    name: 'Logo',
    props: {
      width: {
        type: [
          Boolean,
          Number
        ],
        default: false
      },
      height: {
        type: [
          Boolean,
          Number
        ],
        default: false
      },
      textColor: {
        type: [String],
        default: '#fff'
      },
      dotColor: {
        type: [String],
        default: '#CC1337'
      },
      size: {
        type: [Number],
        default: 177
      }
    },
    data () {
      return {}
    },
    computed: {
      style () {
        let code = ''

        if (this.width) {
          code += `width: ${ this.width }px; `
        }

        if (this.height) {
          code += `height: ${ this.height }px; `
        }

        return code !== '' ? code : false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .st1{fill:#FC9200;}
  .st2{fill:#EC4115;}
</style>
